<template>
    <main class="main">
        <div class="page-header">
            <div class="container">
                <h1>الدفع</h1>
            </div>
        </div>

        <div class="container">
            <div class="row checkout-background" dir="rtl">
                <div class="col-md-4">
                    <div class="sections">
                        <p class="xxl">البيانات الشخصية</p>
                        <div class="form-check">
                            <input
                                type="checkbox"
                                @click="use_my_info = !use_my_info"
                                class="form-check-input mt-1"
                            >
                            <p class="l ml-3">بياناتي الشخصية</p>
                        </div>
                    </div><hr class="mt-2">
                    <form @submit.prevent="checkout">
                        <h2 class="title mb-2">الاسم</h2>
                        <input v-if="use_my_info" disabled type="text" :value="user.name" class="form-control">
                        <input v-else type="text" v-model="name" class="form-control" required>

                        <h2 class="title mb-2">العنوان</h2>
                        <option v-if="use_my_info" class="form-control" :value="user.address" selected disabled>{{ user.address.address + ' - ' + user.address.zoneName }}</option>
                        <select v-else class="form-control form-select" v-model="address" required>
                            <option v-for="address in addresses" :key="address.id" :value="address">{{ address.address + ' - ' + address.zoneName }}</option>
                        </select>

                        <h2 class="title mb-2">الهاتف</h2>
                        <input id="phone" type="tel" v-if="use_my_info" disabled :value="user.address.phone" class="form-control">
                        <input id="phone" type="tel" v-else v-model="phone" class="form-control" required>
                    </form>
                    <div class="alert alert-info" style="display: none;"></div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-7">
                    <div class="sections">
                        <p class="xxl">طرق الدفع</p>
                        <p class="l">{{ payment_method.name }}</p>
                    </div><hr class="mt-2">
                    <div class="payment-methods-types">
                        <div class="custom-control custom-radio">
                            <input
                                type="radio"
                                class="custom-control-input"
                                id="online"
                                name="payment_method"
                                value="online"
                                @click="online = true, upon_receipt = false"
                            >
                            <label class="custom-control-label" for="online">
                                <p class="l">دفع إلكتروني</p>
                            </label>
                        </div>
                        <div class="payment-methods" v-if="online">
                            <div class="custom-control custom-radio" v-for="item in payment_methods.online" :key="item.id">
                                <input type="radio" class="custom-control-input" :id="item.id" name="payment" :value="item" v-model="payment_method">
                                <label class="custom-control-label" :for="item.id">
                                    <img :src="base_url+'/api/public/file/download/'+item.imageUrl" style="width:90px; height:70px;" :alt="item.name">
                                    <p class="l">{{ item.name }}</p>
                                </label>
                            </div>
                        </div>
                        <div class="custom-control custom-radio">
                            <input
                                type="radio"
                                class="custom-control-input"
                                id="upon_receipt"
                                name="payment_method"
                                value="upon_receipt"
                                @click="upon_receipt = true, online = false"
                            >
                            <label class="custom-control-label" for="upon_receipt">
                                <p class="l">عند الاستلام</p>
                            </label>
                        </div>
                        <div class="payment-methods" v-if="upon_receipt">
                            <div class="custom-control custom-radio" v-for="item in payment_methods.upon_receipt" :key="item.id">
                                <input type="radio" class="custom-control-input" :id="item.id" name="payment" :value="item" v-model="payment_method">
                                <label class="custom-control-label" :for="item.id">
                                    <img :src="base_url+'/api/public/file/download/'+item.imageUrl" style="width:90px; height:70px;" :alt="item.name">
                                    <p class="l">{{ item.name }}</p>
                                </label>
                            </div>
                        </div>
                        <div class="custom-control custom-checkbox">
                            <input
                                type="checkbox"
                                class="custom-control-input"
                                id="wallet"
                                name="wallet"
                                value="wallet"
                                @click="use_wallet = !use_wallet"
                            >
                            <label class="custom-control-label" for="wallet">
                                <!-- <img :src="base_url+'/api/public/file/download/'+payment_methods.wallet.imageUrl" style="width:90px; height:70px;" :alt="payment_methods.wallet.name"> -->
                                <p class="l">استخدام {{ payment_methods.wallet.name }}</p>
                            </label>
                            <div v-if="use_wallet">
                                <form>
                                    <p class="l">ادخل القيمة المراد خصمها من المحفظة</p>
                                    <input id="wallet_amount" type="number" :min="0" :max="wallet[0].amount" v-model="wallet_amount" class="form-control">
                                    <p v-if="this.error.wallet_amount.show" class="text-danger">{{ this.error.wallet_amount.msg }}</p>
                                </form>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary mb-4" @click="order">تأكيد الطلب</button>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

export default {
    data(){
        return {
            error: {
                wallet_amount: {
                    msg: 'الكمية المدخلة اكبر من اجمالي الفاتورة',
                    show: false,
                },
            },
            max: 0,
            user: {
                name: '',
                phone: '',
                address: {},
            },
            wallet_amount: 0,
            name: '',
            phone: '',
            address: {},
            wallet: {},
            addresses: [],
            use_my_info: false,
            use_wallet: false,
            payment_method: {},
            payment_methods: {
                online: [],
                upon_receipt: [],
                wallet: {}
            },
            online: false,
            upon_receipt: false,
            total: 0,
            products: [],
            base_url: process.env.VUE_APP_URL,
        }
    },
    mounted() {
        this.user.name = localStorage.getItem('username')
        this.axios({url: process.env.VUE_APP_URL+'/api/payment-methods',
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(response => {
            response.data.forEach(element => {
                element.name.includes('عند الاستلام') ?
                    this.payment_methods.upon_receipt.push(element) :
                    !element.name.includes('المحفظة') ?
                        this.payment_methods.online.push(element) :
                        this.payment_methods.wallet = element
            });
        })

        this.axios({url: process.env.VUE_APP_URL+'/api/addresses',
            method: 'GET',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        .then(response => {
            this.addresses = response.data
            this.user.address = this.addresses.find((address) => address.isDefault===true)
        })

        this.axios({url: process.env.VUE_APP_URL+'/api/customer-wallets?sort=lastModifiedDate,desc',
            method: 'GET',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        .then(response => {
            this.wallet = response.data
        })
    },
    methods: {
        order: function() {
            this.axios
            .get(process.env.VUE_APP_URL+'/api/cart-items',{
                headers: {'Authorization': 'Bearer '+localStorage.getItem('token')}
                })
            .then(response => {
                this.total = 0
                for (let index = 0; index < response.data.length; index++) {
                    this.products[index] = {
                        'price': response.data[index].product.price,
                        'productId': response.data[index].productId,
                        'productName': response.data[index].productName,
                        'quantity': response.data[index].quantity,
                    }
                    response.data[index].product.isSale ?
                        this.total += response.data[index].quantity * response.data[index].product.salePrice :
                        this.total += response.data[index].quantity * response.data[index].product.price
                }
                this.wallet_amount > this.total ? this.error.wallet_amount.show = true : this.total -= this.wallet_amount
                let data = {}
                this.use_my_info ?
                data = {
                    addressAddress: this.user.address.address,
                    addressId: this.user.address.id,
                    addressPhone: this.user.address.phone,
                    lat: this.user.address.lat,
                    lng: this.user.address.lng,
                    orderProductDTOS: this.products,
                    paymentMethodId: this.payment_method.id,
                    paymentType: this.payment_method.paymentType,
                    shippingMethodId: 1,
                    total: this.total,
                    zoneId: this.user.address.zoneId,
                } :
                data = {
                    addressAddress: this.address.address,
                    addressId: this.address.id,
                    addressPhone: this.address.phone,
                    lat: this.address.lat,
                    lng: this.address.lng,
                    orderProductDTOS: this.products,
                    paymentMethodId: this.payment_method.id,
                    paymentType: this.payment_method.paymentType,
                    shippingMethodId: 1,
                    total: this.total,
                    zoneId: this.address.zoneId,
                } 
                // console.log(data);
                this.axios({  
                    url : process.env.VUE_APP_URL+'/api/orders',
                    data: data,
                    method: 'POST',
                    headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
                })
                .then(response => {
                    if(response.data.paymentType == "TADAWUL") {
                         this.axios({
                            url: process.env.VUEAPP_URL+'/api/payment-methods/tadawul-request?orderId='+response.data.id,
                            method: 'GET',
                            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
                        })
                        .then(responsedata => {
                            console.log(responsedata)
                        });
                    }
                    console.log(response)
                })
            })
        }
    }
}
// for (let index = 0; index < response.data.length; index++) {
//                     this.products[index] = {
//                         // 'orderOrderNo': null,
//                         // 'orderProductStatus': 'PENDING',
//                         'price': response.data[index].product.price,
//                         'productId': response.data[index].productId,
//                         // 'productImageUrl': response.data[index].product.imageUrl,
//                         'productName': response.data[index].productName,
//                         'quantity': response.data[index].quantity,
//                         // 'total': response.data[index].quantity * response.data[index].product.price,
//                         // 'vendorId': response.data[index].product.vendor.id,
//                         // 'vendorName': response.data[index].product.vendor.name,
//                     }
//                     this.total += response.data[index].quantity * response.data[index].product.price
//                 }
//                 this.axios({
//                     url: process.en.VUE_APP_URL+'/api/orders',
//                     data: {
//                         addressAddress: null,
//                         addressId: null,
//                         addressPhone: user.phone,
//                         // cityName: this.city.name,
//                         // couponCode: null,
//                         // couponId: null,
//                         // customerId: localStorage.getItem('customer_id'),
//                         // customerName: user.name,
//                         // customerNote: null,
//                         // discountTotal: 0,
//                         // driverId: null,
//                         // driverName: null,
//                         lat: 0,
//                         lng: 0,
//                         // notes: null,
//                         // orderAddress: null,
//                         // orderKey: null,
//                         // orderNo: null,
//                         orderProductDTOS: this.products,
//                         // orderStatus: 'PENDING',
//                         paymentMethodId: this.payment_method.id,
//                         // paymentMethodName: this.payment_method.name,
//                         paymentType: this.payment_method.paymentType,
//                         shippingMethodId: 1,
//                         // shippingMethodName: 'شحن مجاني',
//                         // shippingTotal: null,
//                         total: this.total,
//                         // transactionId: null,
//                         // transactionTransactionNo: null,
//                         zoneId: this.zone.id,
//                         // zoneName: this.zone.name
//                     }
</script>

<style scoped>
    .sections {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    .checkout-background {
        background-color: rgb(255, 255, 255);
        border-radius: 1rem;
        padding: 31px !important;
        margin-left: 4rem;
        margin-bottom: 4rem;
    }
    .payment-methods-types {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: fit-content;
    }
    .payment-methods {
        display: flex;
        justify-content: space-between;
    }
</style>